import { ContentLibraryTemplateParams } from 'features/content-library/types';

export const replaceUrlParams = (url: string, params: ContentLibraryTemplateParams) => {
  if (params && Object.keys(params)) {
    return url.replace(/{(\w+)}/g, (_, key) => params[key] || '');
  }

  return url;
};

export const formatUrlParams = (url: string, method: 'encode' | 'decode') => {
  const [baseUrl, queryString] = url.split('?');
  if (!queryString) return url;

  const encodedParams = queryString
    .split('&')
    .map((param) => {
      const [key, value] = param.split('=');
      return `${key}=${method === 'encode' ? encodeURIComponent(value) : decodeURIComponent(value)}`;
    })
    .join('&');

  return `${baseUrl}?${encodedParams}`;
};
