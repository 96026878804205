import { SFrameManager } from 'features/e2ee/SFrameManager';
import {
  publisherLeft,
  receivingPluginCleanup,
  remoteTrackReceived,
  subscriberAttached,
  videoroomError,
} from 'features/streaming/actions';
import { FeedId, SubscriberMid, SubscribeStreamList } from 'features/streaming/types';
import Janus, { JanusJS } from 'lib/janus';
import { store } from 'store/store';
import { noop } from 'utils/flow';
import { logger } from 'utils/logger';
import {
  ControlledReceivingHandle,
  VideoroomConnectionState,
} from 'utils/webrtc/ControlledReceivingHandle';
import { GENERIC_JANUS_ERROR } from 'utils/webrtc/errors';
import { RTCClient, StreamSubscriptionState } from 'utils/webrtc/index';
import {
  iceRestartTemplate,
  joinAsSubscriberTemplate,
  sendMessage,
  startSubscriptionTemplate,
  subscribeTemplate,
  unsubscribeTemplate,
} from 'utils/webrtc/messages';
import { BaseReceiver } from 'utils/webrtc/receiving/BaseReceiver';
import { JanusConnection } from 'utils/webrtc/types';
import { handleAttachError } from 'utils/webrtc/handleAttachError';

export class VideoroomReceiver extends BaseReceiver {
  queue: Record<string, SubscribeStreamList> = {};

  sentRequests: Record<string, any[]> = {};

  attachPlugin = (pluginHandle: string, connection: JanusConnection) => {
    this.connection = connection;

    connection.janus.attach({
      plugin: 'janus.plugin.videoroom',
      opaqueId: connection.handle,
      success: async (videoroom) => {
        this.plugin = new ControlledReceivingHandle(videoroom, pluginHandle, connection);

        this.consumeQueue(connection.handle);
      },
      error: handleAttachError('attach_receiving_feed'),
      consentDialog: noop,
      onmessage: this.onMessage,
      onlocaltrack: noop,
      onremotetrack: (track, mid, on) => {
        if (on) {
          store.dispatch(remoteTrackReceived({ pluginHandle, track, mid }));
        }
      },
      oncleanup: () => store.dispatch(receivingPluginCleanup(pluginHandle)),
      webrtcState: (on) => {
        Janus.log(`Receiving Feed: WebRTC PeerConnection is ${on ? 'up' : 'down'} now`);
      },
      iceState: this.onIceState,
      slowLink: (uplink, lost, mid) => {
        Janus.warn(
          `Janus reports problems (${
            uplink ? 'sending' : 'receiving'
          }) packets on mid ${mid} (${lost} lost packets)`
        );
      },
      sframe: SFrameManager.getClient(),
    });
  };

  private onMessage = (
    message: JanusJS.EvtMessage,
    messageJsep?: JanusJS.JSEP,
    eventBody?: JanusJS.EvtBody
  ) => {
    const { plugin } = this;

    if (!plugin) {
      return;
    }

    logger.debug(`Got a remote message in receiving feed:`, message);
    if (messageJsep) {
      plugin.janusPlugin.createAnswer({
        jsep: messageJsep,
        media: { audioSend: false, videoSend: false },
        success: async (jsep: JanusJS.JSEP) => {
          try {
            await sendMessage(
              plugin.janusPlugin,
              startSubscriptionTemplate(RTCClient.roomId!),
              jsep
            );
          } catch (e) {
            if (!RTCClient.supressErrors) {
              logger.error(e);
            }
          }
        },
        error: () => GENERIC_JANUS_ERROR('receiving_feed'),
      });
    }

    if (message.streams) {
      this.feed.handleSubscriptionsUpdate(plugin, message.streams);
    }

    const event = message.videoroom;
    const { handle } = plugin;

    switch (event) {
      case 'attached': {
        if (eventBody?.transaction) {
          delete this.sentRequests[eventBody.transaction];
        }

        store.dispatch(
          subscriberAttached({
            handle,
            message: null,
          })
        );
        break;
      }
      case 'leaving': {
        store.dispatch(
          publisherLeft({
            handle: this.feed.handle,
            message: message.leaving,
          })
        );
        break;
      }
      case 'event': {
        const { substream, temporal, mid } = message;

        if (
          (substream !== null && substream !== undefined) ||
          (temporal !== null && temporal !== undefined)
        ) {
          logger.debug('Simulcast substream update: ', substream);
          if (temporal) {
            logger.debug('Simulcast temporal update: ', temporal);
          }
          this.feed.updateSimulcastValue(mid, substream, temporal);
        } else if (message.started) {
          logger.debug(`Videoroom ${handle} started`);
        } else if (message.configured) {
          logger.debug(`Videoroom ${handle} configured`);
        } else if (message.unpublished && message.unpublished !== 'ok') {
          // maybe we don't need this one;
          logger.error('Receiving Feed unpublished', message.unpublished);

          store.dispatch(
            publisherLeft({
              handle: this.feed.handle,
              message: message.unpublished,
            })
          );
        } else if (message.error_code) {
          store.dispatch(videoroomError({ handle, message, eventBody }));
        } else {
          const messageStr = 'Got an anomalous message in receiving feed';

          logger.remote({ system: true, capture: 'streaming' }).warn(messageStr, message);
        }
        break;
      }
      case 'updated': {
        if (eventBody?.transaction) {
          delete this.sentRequests[eventBody.transaction];
        }

        break;
      }
      default:
        logger
          .remote({ system: true, capture: 'streaming' })
          .warn('Got an anomalous message in receiving feed', message);
    }
  };

  subscribe = async (connectionHandle: string, streams: SubscribeStreamList) => {
    const feed = this.plugin;

    if (feed && streams.length) {
      let roomUsers = this.feed.media.feedsWithVideo.length;
      const subscribeFeeds: string[] = [];

      streams = streams.map((stream) => {
        if (!subscribeFeeds.includes(stream.feed)) {
          subscribeFeeds.push(stream.feed);
          roomUsers += 1;
        }

        const [substream, temporal] = this.feed.getBaseSimulcastConfig(roomUsers);

        if (stream.mid) {
          const streamMedia = this.feed.media.stream(stream.feed, stream.mid);

          streamMedia.setSubscriptionState(StreamSubscriptionState.connecting);
        }

        return { ...stream, substream, temporal };
      });

      switch (feed.connectionState) {
        case VideoroomConnectionState.connected: {
          if (this.plugin) {
            const { request } = await sendMessage(
              this.plugin?.janusPlugin,
              subscribeTemplate(streams)
            );

            if (request.transaction && request.body?.streams) {
              this.sentRequests[request.transaction] = request.body.streams;
            }

            this.plugin?.processQueue();
          }
          break;
        }
        case VideoroomConnectionState.connecting: {
          logger.warn(`Feed ${feed.handle} is in connecting state, will subscribe later`);

          streams.forEach((stream) => {
            if (stream.mid) {
              const streamMedia = this.feed.media.stream(stream.feed, stream.mid);

              // TODO: What's going on in this block. what if state is already a reasonable state and we reset it back to created?
              // could this place be breaking room subscriptions?
              streamMedia.setSubscriptionState(StreamSubscriptionState.created);
            }
          });

          feed.enqueueSubscription(streams);
          break;
        }
        case VideoroomConnectionState.created: {
          feed.setConnectionState(VideoroomConnectionState.connecting);

          const { request } = await sendMessage(
            feed.janusPlugin,
            joinAsSubscriberTemplate(
              RTCClient.roomId!,
              RTCClient.roomPin!,
              streams,
              RTCClient.privateId,
              { temporal: 0, substream: 0 }
            )
          );

          if (request.transaction && request.body?.streams) {
            this.sentRequests[request.transaction] = request.body.streams;
          }

          break;
        }
        case VideoroomConnectionState.disconnected: {
          // TODO: Will we even have such state? Is it reasonable to keep?
          break;
        }
        default:
          throw new Error('Unexpected connection state');
      }
    } else {
      this.queueSubscription(connectionHandle, streams);
    }
  };

  unsubscribe = (feedId: FeedId, mid?: SubscriberMid) => {
    this.plugin?.janusPlugin.send({
      message: unsubscribeTemplate([{ feed: feedId, sub_mid: mid }]),
    });
  };

  private queueSubscription = (connectionHandle: string, streams: SubscribeStreamList) => {
    if (this.queue[connectionHandle]) {
      this.queue[connectionHandle] = this.queue[connectionHandle].concat(streams);
    } else {
      this.queue[connectionHandle] = streams;
    }
  };

  private consumeQueue = (connectionHandle: string) => {
    if (!this.queue[connectionHandle]?.length) return;

    this.subscribe(connectionHandle, this.queue[connectionHandle]);

    this.queue[connectionHandle] = [];
  };

  iceRestartHandler = async (feed: ControlledReceivingHandle) => {
    await sendMessage(feed.janusPlugin, iceRestartTemplate());
  };

  retrySubscription = (connectionHandle: string, transaction: string, errorMessage: string) => {
    const excludedFeed = errorMessage.match(/\(([^)]+)\)/)?.[1];
    if (excludedFeed && this.sentRequests[transaction]) {
      logger.remote().warn('Retrying subscription, excluding feed:', excludedFeed);

      this.subscribe(
        connectionHandle,
        this.sentRequests[transaction].filter((stream) => stream.feed !== excludedFeed)
      );

      delete this.sentRequests[transaction];
    }
  };
}
